@import '../../../assets/css/variable.scss';
.my_nav{
  padding: 30px 0;
  .container-fluid{
    padding: 0;    
  }
  .navbar-brand{
    margin-right: 60px;
    img{
      height: 45px;
    }
  }
  .nav-item{
    margin-right: 30px;
    .nav-link{
      padding: 0.5rem 0 !important;
      color: $first-color;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.navbar-toggler{
  font-size: 15px !important;
}

@media (max-width:1200px){
  .my_nav{
    .nav-item{
      margin-right: 20px;
    }
  } 
}
@media (max-width:992px){
  .my_nav{
    padding: 10px 0;
    .blue_btn {
      display: inline-block;
      width: 200px;
    }
  } 
}
