.coin_card{
  padding: 15px 10px 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-radius: 10px;
}
.coin_card .left_side_card{
  flex-grow: 1;  
  padding-right: 10px;
}
.coin_card .left_side_card input{
  color: #7B3FE4;
  font-size: 20px;
  margin-bottom: 0;
  line-height: 24px;
  font-weight: 600;
  background: transparent;
  border: none !important;
  outline: none;
  width: 100%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;  
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
}

.coin_card .right_side_card{
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.coin_card .right_side_card .coin_icon{
  margin-right: 16px;
}
.coin_card .right_side_card .text_right{
  width: 75px;
}
.coin_card .right_side_card .text_right h5{
  color: #fff;
  font-size: 21px;
  margin-bottom: 0;
  line-height: 25px;
  font-weight: 600;
}
.coin_card .right_side_card .text_right h5 span{
  margin-left: 15px;
}
.coin_card .right_side_card .text_right p{
  color: #93cdbf;
  font-size: 10px;
  margin-bottom: 0;
  line-height: 14px;
  text-transform: capitalize;
}

/* selcect  */
.drop_down_coin_card{
  width: 100%;
}
.coin_card_select__control{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  border-radius:  0  !important;
  height: 41px;
}
.coin_card_select__value-container {
  padding: 0 !important;
}
.coin_card_select__indicator-separator {
  display: none;
}
.coin_card_select__indicator {  
  display: none !important;
}
.coin_card_select__indicator svg{
  display: none;  
}
.coin_card_select__single-value{
  color: #fff !important;
  font-size: 16px;
  text-transform: uppercase; 
}
.coin_card_select_icon{  
  display: flex;
  align-items: center;  
}
.coin_card_select_icon img{
  margin-right: 10px;
}
.coin_card_select_icon>div{
  display: flex;
  flex-direction: column;  
  min-width: 140px;
  padding-right: 30px;
  background: url(../../../assets/images/select_arrow.svg);
  background-repeat: no-repeat;
  background-position: center right;  
}
.coin_card_select_icon .coin_name{
  font-size: 18px;
  display: block;
  line-height: 20px;
  font-weight: 500;    
  color: #0F2052;
}
.coin_card_select_icon .amount_balance{
  color: #8C96B5;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}
.coin_card_select__menu{
  background-color: #D1E6F1 !important;
  margin-bottom: 0 !important;
    margin-top: 0 !important;
    border: 1px solid #93cdbf;
}
.coin_card_select__option--is-focused{
  background-color: #D1E6F1 !important;
}
.coin_card_select__option--is-selected{
  background-color: #7B3FE4 !important;  
}
.coin_card_select_menu-list{
  background: #232539 !important;
}
.coin_card_select__option{
  display: flex;
  align-items: center;
}
.coin_card_select__option {
  display: flex !important;
  align-items: center;
}
.coin_card_select__option img{
  margin-right: 5px;
}
@media (max-width:576px){  
  .coin_card{
    padding: 15px 15px;
  }
  .coin_card_select_icon > div{
    background-size: 20px;
    padding-right: 20px;
    min-width: 110px;
  }
}
