.buy-outer{
  padding: 100px 0;
}
.buy-card{
  background: #E8F0FA;
  border-radius: 20px;
  padding: 40px;
  h3{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
  }
  .toLine{
    display: block;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: #51608B;
    text-align: center;
    margin: 14px 0;
    &::after{
      content: '';
      top: 13px;
      left: 0;
      width: 46%;
      position: absolute;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    &::before{
      right: 0;
      top: 13px;
      content: '';
      width: 46%;
      position: absolute;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .blue_btn{
    margin-top: 40px;
  }
  >div{
    width: 100% !important;
  }
}
.buy-card{
  .subhead.subhead-1
  {
    font-size: 28px;
  }
}


@media (max-width:992px){
  .buy-outer{
    padding: 40px 0 0 0;
  }
}
@media (max-width:576px){
  .buy-card{
    padding: 30px 20px;
  }
}