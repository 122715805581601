.governance-detail{
  padding: 54px 0 160px 0;
}
.back-link{
  color: #8D99BB;
  font-size: 16px;
  display: flex;
  align-items: center;
  img{
    margin-right: 20px;
  }
  &:hover{
    color: #8D99BB;
  }
}
.g-d-bluesection{
  background: #7B3FE4 url(../../assets//images/g-d-bg.png) no-repeat;
  background-position: center;
  background-size: auto 100%;
  padding: 63px 0 44px 0;
  h4{
    color: #fff;
    font-size: 22px;
    margin-bottom: 0;
    line-height: 33px;
    font-weight: 600;
  }
}
.ending-voting{
  display: flex;
  flex-wrap: wrap;
  p{
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
    span{
      color: #A6B8ED;
    }
    &:first-child{
      margin-right: 33px;
      padding-right: 33px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;  
        background: rgba(255, 255, 255, 0.6);
        right: 0;
        top: 10px;
        border-radius: 50%;
      }
    }
  }
}
.progress-outer{
  margin-top: 66px;
  .progress-text{
    display: flex;
    justify-content: space-between;
    color: #F9F9F9;
    margin-bottom: 27px;
    h5{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0;
    }
    h3{
      font-weight: 500;
      font-size: 40px;
      margin-bottom: 0;
    }
  }
  .progress{
    height: 4px;
    border-radius:  0 !important;
    background-color: #060C1D;
    &.green{
      .progress-bar{
        background: #49BA4E;
      }
    }
    &.red{
      .progress-bar{
        background: #C85048;
      }
    }
  }
}
.g-d-detailtext{
  margin-top: 60px;
  h3{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  p{
    margin-bottom: 20px;
  }
  .btns-approve-cancel{
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 40px;
    margin-top: 60px;
    .btn{
      flex-grow: 1;
      flex-basis: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      padding: 14px 10px;
      color: #FFFFFF;
      border-radius: 100px;
      &:first-child{
        background: rgba(80, 192, 118, 0.8);
        border: 1px solid #E2E2E3;
        margin-right: 4px;
      }
      &:last-child{
        background: #E95B5B;
        border: 1px solid #E95B5B;
        margin-left: 4px;
      }
      &:hover{
        opacity: 0.9;
      }
    }
  }
}

@media (max-width:992px){
  .governance-detail {
    padding: 30px 0 ;
  }
}
@media (max-width:768px){
  .progress-outer {
    margin-top: 30px;
    .progress-text{
      margin-bottom: 15px;
    }
  }
  .g-d-detailtext{
    .btns-approve-cancel{
      padding-top: 20px;
      margin-top: 20px;
    }
  }
}
@media (max-width:576px){
  .g-d-bluesection{
    padding: 40px 0 40px 0;
    h4{
      margin-bottom: 15px;
    }
  }
  .ending-voting p:first-child{
    margin-right: 0 !important;
    padding-right: 0 !important;
    &::after{
      display: none;
    }
  }
  .g-d-detailtext{
    margin-top: 25px;
  }
}