@import '../../assets/css/variable.scss';
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Outlined");

.banner-bg-video{
  position: absolute;
  z-index: -1;
  left: 0;
  width: 100%;
  height: 100%;
  top: -100px;
  object-fit: cover;
}
.home-banner{
  padding: 154px 0 137px 0;
  // background: url(../../assets/images/home/banner.png) no-repeat;
  background-position: right bottom;
  max-width:1440px;
  margin: 0 auto;
  position: relative;
  .heading-with-btn{
    h1{
      font-size: 120px;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0;
      line-height: 140px;      
    }
    p{      
      font-weight: 600;
      line-height: 33px;
      margin: 36px 0 76px 0;
    }
  }  
  .scroll-down{   
    margin-top: 183px; 
    >div{      
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      color: $third-color;
      display: inline-flex;
      align-items: center;   
      cursor: pointer;   
      img{
        margin-right: 15px;
      }
    }    
  }
}
// video section 
.react-player{
  height: 550px !important;
  .react-player__preview{
    border-radius: 0 40px 40px 0;
  }
}
.videosection{
  .videosection-inner{
    background: #7B3FE4;
    border-radius: 40px;
    .heading-with-btn{
      padding-left: 70px;
      h1{
        color: #F7F6FE;
        font-weight: 700;
      }
      p{
        color: #fff;
        font-size: 17px;
        line-height: 30px;
        margin: 20px 0 30px 0;
      }
      button{
        background: #fff;
        &:hover{
          color: #7B3FE4;
        }
      }
    }
  }
}
// section 2
.home-section-2{
  background: linear-gradient(78.78deg, rgba(255, 255, 255, 0) 45.18%, #90B4FF 381.45%);
  padding: 107px 0 180px 0;
  border-radius: 40px;
  .heading-with-btn{
    h1{
      font-weight: 700;      
    }
    p{
      font-size: 17px;
      font-weight: 600;
      margin: 64px 0;
    }
  }
}
.partner_section{
  div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 60px;
    img{
      height: 92px;
      width: 92px;
      border-radius: 50%;
      box-shadow: 0px 0px 117px 12px rgba(0, 0, 0, 0.17);
    }
    a{
      &:hover{
        transform: scale(1.1);
        transition-duration: 0.3s;
      }
    }
    &:nth-child(2){
      margin: 56px 0;
      padding-left: 60px;
      padding-right: 0;
    }
  }
}
// section 3
.home-section-3{
  padding: 174px 0 140px 0;
  background-image: url(../../assets/images/home/shap-bg.png);
  background-repeat: no-repeat;
  background-position: right bottom 15%;
  .heading-with-btn{
    h1{
      font-weight: 700;      
    }
    p{
      font-size: 17px;
      font-weight: 600;
      margin: 64px 0;
    }
  }
}

// section 4
.tokenomics{
  padding: 110px 0 120px 0;
  background: url(../../assets/images/home/tokenomic.png),linear-gradient(180deg, #7B3FE4 0%, #5527A7 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.supply{
  text-align: right;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  span{
    font-weight: 700;
    font-size: 24px;
    color: #F9FDFF;
    line-height: 4;
  }
  p{
    display: inline-block;
    font-weight: 700;
    font-size: 80px;
    margin-left: 20px;
    margin-bottom: 0;
    color: #F9FDFF;
    line-height: 1.7;
  }
}
.chart-line{
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 60px;
  height: 200px;
  align-items: flex-end;
  overflow: hidden;
  div{
    background: #8F77FC;
    width: 44px;
    border-radius: 4px;
    cursor: pointer;
    &.active{
      background: #F9FDFF;
    }
    &:hover{
      background: #F9FDFF;
    }
  }
}
.chart-text{
  color: #BACAF6;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  height: 70px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  span{
    font-weight: 700;
    font-size: 24px;
    margin-right: 23px;
  }
  &.active{
    color: #FFFFFF;
    border-top: 1px solid #FFFFFF;
  }
  &:hover{
    color: #FFFFFF;
    border-top: 1px solid #FFFFFF;
  }
}

// section 5
.contract{
  padding: 130px 0;
  background: #EDF9FF;
  border-radius: 40px 40px 0 0;
}
.copy-clip{
  background: #E7DCFC;
  height: 83px;
  border-radius: 8px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  color: #7B3FE4;
  padding: 0 23px 0 75px;
  word-break: break-all;
  .iconleft{    
    position: absolute;
    width: 108px;
    height: 108px;
    background-color: #7B3FE4;
    border-radius: 100px;
    left: -48px;
    top: -12px;
    background-image: url(../../assets/images/home/union.svg);
    background-repeat: no-repeat;
    background-position: right 18px center;
  }
  span{
    margin-left: 30px;
    cursor: pointer;
  }
}

// section 6
.nft-tab-outer{
  border-radius: 0 0 40px 40px;
  padding-bottom: 26px;
}
.nft-tab{
  background: #EDF9FF;
  border-radius: 40px;
  padding: 60px 0;
  margin: 0 21px;
}
.shadow-image{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background: radial-gradient(50% 50% at 50% 50%, rgba(140, 88, 233, 0.37) 0%, rgba(127, 80, 215, 0.222229) 60.73%, rgba(10, 14, 59, 0) 100%);
}
.video-squar{
  video{
    height: 550px;
  }
}
.custometab{
  border: none !important;
  margin-bottom: 40px !important;
  .nav-item{     
    &:first-child{
      margin-right: -8px;
      .nav-link{
        border-radius:  8px 0 0 8px !important;
        border-right: none !important;
      }      
    }
    &:last-child{
      .nav-link{
        border-radius:  0 8px 8px 0 !important;
        border-left: none !important;
      }      
    }
    .nav-link{
      height: 70px;      
      border: 1px solid #000 !important;
      color: #000;
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
      &.active{
        background: #000;
        box-shadow: inset 0px 0px 4px rgba(255, 255, 255, 0.25);
        border:1px solid #000 !important;
        color: #fff;
        border-radius: 8px !important;
      }
    } 
  }  
}
.border-below{
  border-bottom: 1px solid #112250;
}
.hometabtext{
  padding: 30px 0;
  .buy{    
    p{
      font-weight: 700;
      font-size: 24px;
      color: #112250;
      margin-bottom: 10px;
    }
    div{
      font-weight: 700;
      font-size: 80px;
      color: #112250;
      line-height: 1;
      span{
        font-size: 30px;
      }
    }
  }
  .listText{
    p{
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 13px;
      color: #112250;
      span{
        font-weight: 700;
      }
    }
  }
}

// section 7 client
.client{
  padding: 140px 0 100px 0;
  h2{
    margin-bottom: 100px;
  }
}
.clientLogo-row{
  margin-left: -4px;
  margin-right: -4px;
  >div{
    padding-left: 4px;
    padding-right: 4px;
  }
  .client-card{
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EDF9FF;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
  }
}
.explorecard{
  border: none !important;
  background-color: #F9FDFF !important;
  .card-img-top{
    border-radius: 20px;
    object-position: center;
  }
  .card-body{
    padding: 34px 0 30px 0;
    .card-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 33px;
      a{
        border: 1px solid rgba(15, 32, 82, 0.1);
        border-radius: 8px;
        width: 50px;
        height: 50px;
        display: inline-block;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          border: 1px solid #7B3FE4;
        }
      }
    }
    .card-text{
      font-weight: 600;
    }
  }  
}
.mb-50{
  margin-bottom: 50px;
}
// news section 
.newscard {
  border-radius: 20px !important;
  margin-bottom: 20px;
  .card-img-top{
    border-radius: 20px;
    height: 250px;
    object-fit: cover;
  }
  .card-title{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
// join community
.join-community{
  background: url(../../assets/images/home/community-bg.png) no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 40px;
  margin: 90px 24px 0 24px; 
  padding: 100px 0 110px 0;
  p{
    color: #F7F6FE;
    width: 80%;
  }  
}
.social-links{
  display: flex;
  margin-top: 40px;
  a{
    display: inline-block;
    margin-right: 30px;
    &:hover
    {
      opacity: 0.8;
    }
  }
}
.seen_news{
  overflow: visible;
  white-space: nowrap;
  a{
    margin-right: 25px;
  }
}

// responsive 
@media (max-width:1200px){
  .video-squar{
    video{
      height: 100%;
      width: 100%;
    }
  }
  .react-player{
    height: 450px !important;
  } 
  .videosection{
    .videosection-inner{
      .heading-with-btn{
        padding-left: 40px;
        h1{
          font-size: 45px;
          line-height: 60px;
        }
      }
    }
  }  
  .partner_section{
    div{
      
      padding-right: 40px;
      img{
        height: 70px;
        width: 70px;       
        box-shadow: 0px 0px 117px 12px rgba(0, 0, 0, 0.17);
      }
      &:nth-child(2){
        margin: 45px 0;
        padding-left: 40px;
        padding-right: 0;
      }
    }
  }
}
@media (max-width:992px){
  .my_nav{
    .nav-item{
      margin-right: 20px;
    }
  } 
  .banner-bg-video{
    top: -50px;
    video{
      height: 450px !important;
    }
  }
  .home-banner{
    padding: 60px 0;
    background: none;
    .heading-with-btn{
      
      h1{
        font-size: 70px;
        line-height: 90px;
      }
      p{
        margin: 30px 0;
      }      
    }
    .scroll-down{
      margin-top: 70px;      
    }
  }
  .react-player{
    height: 400px !important;
    .react-player__preview{
      border-radius: 20px;
    }
  }  
  .videosection{
    .videosection-inner{
      border-radius: 20px;
      .heading-with-btn{
        padding: 40px;
        h1{
          font-size: 45px;
          line-height: 60px;
        }
      }
    }
  } 
  .home-section-2{
    padding: 40px 0;
    .heading-with-btn{
      p{
        margin: 30px 0;
      }
    }
  }
  .home-section-3{
    padding: 40px 0;
    .heading-with-btn{
      p{
        margin: 30px 0;
      }
    }
  }
  .tokenomics{
    padding: 40px 0;
  }
  .supply{
    span{
      font-size: 16px;
    }
    p{
      font-size: 45px;      
    }
  }
  .contract{
    padding: 40px 0;
  }
  .nft-tab{
    padding: 30px 0;
  }
  .custometab{
    margin-bottom: 20px !important;
    .nav-item{
      .nav-link{
        height: 50px;
        font-size: 16px;
      }
    } 
  } 
  .hometabtext{
    .buy{
      p{
        font-size: 18px;
      }
      div{
        font-size: 45px;
        span{
          font-size: 18px;
        }
      }
    }
    .listText {
      p{
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  } 
  .client{
    padding: 40px 0;
    h2{
      margin-bottom: 25px;
    }
  }
  .explorecard {
    
    .card-body{
      padding: 10px 0;
      .card-title{
        font-size: 21px;
        margin-bottom: 10px;
        a{
          width: 40px;
          height: 40px;
          img{
            height: 15px;
          }
        }
      }
    }
  }
  .mb-50{
    margin-bottom: 20px;
  }
  .join-community{
    padding: 40px 0;
    margin: 25px 10px 0 10px;
  }
  .social-links{
    margin-top: 20px;
    a{      
      img{
        height: 30px;
      }
    }
  }
  .partner_section{
    div{
      
      padding-right: 30px;
      img{
        height: 50px;
        width: 50px;               
      }
      &:nth-child(2){
        margin: 35px 0;
        padding-left: 30px;        
      }
    }
  }
}
@media (max-width:768px){
  .banner-bg-video{
    position: static;
    video{
      height: 350px !important;
    }
  }
  .home-banner{
    padding: 0 0 30px 0;
    .heading-with-btn{
      text-align: center;
    }
    .scroll-down{
      text-align: center;
    }
  }
  .react-player{
    height: 300px !important;
  }  
  .player-icon{
    height: 50px;
    width: 50px;
    object-fit: cover;
  }
  .chart-line{
    margin-top: 20px;
    height: 120px;
    margin-bottom: 30px;
    >div{
      width: 25px;
    }
  }
  .chart-text{
    font-size: 15px;
    height: 45px;
    span{
      font-size: 16px;
    }
  }
  .copy-clip{
    height: 65px;
    padding: 0 23px 0 50px;
    margin-bottom: 15px;
    .iconleft{
      background-position: right 10px center;
      background-size: 25px;
      width: 90px;
      height: 90px;
    }
  }
  .clientLogo-row{
    .client-card{
      height: 90px;
    }
  } 
  .home-section-3{
    background: none;
  }
  .seen_news{
    a{
      margin-right: 10px;
      display: inline-block;
      margin-bottom: 10px;
      img{
        height: 15px;
      }
    }
  }
}
@media (max-width: 576px){
  .react-player{
    height: 250px !important;
  }  
  .home-banner {
  
    background: none;
    .btn-outer{
      .blue_btn{
        margin-bottom: 15px;
      }
    }
  }
  .home-section-3{   
    .heading-with-btn{
      p{
        margin: 15px 0 25px 0;
      }
    }
  }
  .tokenomics{
    border-radius: 0;
  }
  .nft-tab{
    margin: 0 5px;
    border-radius: 0;
  }
  .clientLogo-row{
    .client-card {
      height: 75px;
      padding: 0 10px;
    }
  }
  .mb-50{
    margin-bottom: 0;
  }
  .explorecard {
    margin-bottom: 20px;
  }
  .join-community{
    margin: 25px 0 0 0 ;
    border-radius: 0;
  }
  .videosection{
    .videosection-inner{
      border-radius: 20px;
      .heading-with-btn{
        padding: 40px 20px;
        h1{
          font-size: 38px;
          line-height: 45px;
        }
      }
    }
  } 
  .custometab .nav-item .nav-link{
    font-size: 14px !important;
  }
  .hometabtext{
    padding: 15px 0;
  }
}
