h3 {
  margin-top: 0px;
}

// button {
//   width: 100%;
//   padding: 20px 20px !important;
//   margin-bottom: 10px;
// }

.muted {
  opacity: 0.5;
}
