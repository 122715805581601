h3 {
    margin-top: 0px;
  }
  .muted {
    opacity: 0.5;
  }
  .modal_outer{
    overflow-y: auto;
    
  }
  .wallet_modal_inner{
    background-color: #fff;
  }
.model_white_outer{
  background-color: #fff;
  width: 50%;
  padding: 60px 50px;
  border-radius: 15px;
}
@media (max-width:992px){
  .model_white_outer{
    width: 80%;
    padding: 40px 30px;
  }
}
@media (max-width:768px){
  .model_white_outer{
    padding: 25px 20px;
  }
}