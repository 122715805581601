@import '../css/variable.scss';

.blue_btn{
  background-color: $btn-bg;
  font-size: 18px;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: inset 0px 0px 4px rgba(255, 255, 255, 0.25);
  border-radius: 100px;
  border: none;
  font-weight: 700;
  &.h50{
    height: 50px;
  }
  &.h70{
    height: 70px;
  }
  &.h60{
    height: 60px;
  }
  &.plr-30{
    padding-left: 30px;
    padding-right: 30px;
  }
  &.arrow{
    width: 277px;
    padding-left: 30px;
    padding-right: 30px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
  &.white_btn{
    border: 1px solid rgba(123, 63, 228, 0.15);
    background-color: transparent;
    color: $btn-bg;
    &:hover{
      color: #fff;
    }
  }
  &.white_outline{
    border: 1px solid #fff;
    background-color: transparent !important;
    color: #fff;
    &:hover{
      color:#fff !important;
    }
  }
  &.dark_btn{
    background-color: #090C3D;
  }
  &.dark_btn_outline{
    background-color: transparent;
    color: #090C3D;
    border: 1px solid #090C3D;
    width: 200px;
    &:hover{
      background-color: transparent;
    }
  }
  &:hover{
    background-color: #6b2ccf;
  }
}
.curser-pointer{
  cursor: pointer;
}
.wordwrap{
  word-break: break-all;
}
.heading-with-btn{
  h1{
    font-size: 60px;
    line-height: 78px;
    span{
      color:$third-color;
    }
  }
  p{
    color: $second-color;
    font-size: 22px;
    line-height: 26px;
    margin: 46px 0;
  }
}

.common-heading{
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  &.white{
    color: #F9FDFF;
  }
  span{
    color: $third-color;
  }
}

@media (max-width:992px){
  .blue_btn{
    height: 50px !important;
    &.arrow{
      img{
        height: 15px;
      }
    }
  }
  .common-heading{
    font-size: 45px;
    line-height: 60px;
  }
  .heading-with-btn{
    h1{
      font-size: 45px;
      line-height: 60px;
    }
  }
}
@media (max-width:576px){
  .blue_btn{
    height: 45px !important;
    font-size: 16px;
    &.arrow{
      width: 214px;
      img{
        height: 12px;
      }
    }
  }
  .common-heading{
    font-size: 38px;
    line-height: 45px;
  }
  .heading-with-btn{
    h1{
      font-size: 38px;
      line-height: 45px;
    }
  }
  
}