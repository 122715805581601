label {
  font-size: 0.7rem;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.data {
  margin-bottom: 20px;
  font-size: 1.5em;
}
.heading_data_card {
  font-size: 22px;
  color: #93cdbf;
  display: flex;
  justify-content: center;
}
