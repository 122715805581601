.about-banner{
  padding: 100px 0;
  .heading-with-btn{
    text-align: center;
    h1{
      text-transform: uppercase;
      font-weight: 600;
    }
    p{
      font-weight: 700;    
    }
  }
}

// second section 
.about-image-section{
  height: 540px;
  background: url(../../assets/images/about/shap.png) no-repeat;
  background-size: cover;
  background-position: center;  
  border-radius: 40px;
  margin: 0 24px;  
}

// mission vision 
.mission{
  h4{
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 25px;
    span{
      color: #7B3FE4;
    }
  }
  p{
    margin-bottom: 0;
  }
}
.vision_outer{
  background: #EDF9FF;
  border-radius: 40px;
  margin: 0 24px;
}
// team 
.team-section{
  padding-top: 50px;
  .card{
    text-align: center;
    background-color: #DEEFF8;
    border: none;
    padding: 35px 20px;
    margin-bottom: 15px;
    .profile_img{
      margin-bottom: 20px;
      img{
        height: 100px;
        width: 100px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
    .social_icon{
      width: 150px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      a{
        background-color: #7B3FE4;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #7B3FE4;
      }
    }
  }
}
// our value 
.our-promise{
  padding: 156px 0 230px 0;
  background: url(../../assets/images/home/tokenomic.png),linear-gradient(180deg, #7B3FE4 0%, #5527A7 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  margin: 0 24px;
  padding: 180px 0;
  h3{
    font-weight: 600;
    font-size: 22px;
    color: #FFF;
    margin-bottom: 40px;
  }
  p{
    font-weight: 700;
    font-size: 30px;
    color: #FFF;
    line-height: 36px;
    margin-bottom: 0;
  }
}
.value-heading{
  font-weight: 600;
  font-size: 22px;
  color: #F9FDFF;
  margin-bottom: 100px;
}

.our-value{
  .embla {
    position: relative;    
    padding: 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }  
  .embla__viewport {
    overflow: hidden;
    width: 100%;
  }  
  .embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
  }  
  .embla__viewport.is-dragging {
    cursor: grabbing;
  }  
  .embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
  }  
  .embla__slide {
    position: relative;
    min-width:65%;
    padding-left: 10px;
  }  
  .embla__slide__inner {
    position: relative;
    overflow: hidden;    
  }  
  .embla__slide__img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
  }  
  .embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
  }  
  .embla__button:disabled {
    cursor: default;
    opacity: 0.3;
  }  
  .embla__button__svg {
    width: 100%;
    height: 100%;
  }  
  .embla__button--prev {
    left: 27px;
  }  
  .embla__button--next {
    right: 27px;
  }  
}

// responsive 
@media (max-width:992px){
  .about-banner{
    padding: 60px 0;
    .heading-with-btn{
      p{
        margin: 35px 0;
      }
    }
  }
  .about-image-section{
    height: 300px;
  }
  .vision_outer{
    margin: 0;
  }
  .our-promise{
    padding: 70px 0;
    h3{
      margin-bottom: 25px;
    }
    p{
      font-size: 23px;
    }
  }
  .our-value{
    padding: 80px 0 100px 0;
  }
  .value-heading{
    margin-bottom: 50px;
  }
}
@media (max-width:768px){
  .about-image-section{
    border-radius: 20px;
    margin: 0 5px;
  }
  .mission{
    h4{
      font-size: 20px;
      line-height: 29px;
      margin-bottom: 15px;
    }
  } 
  .our-value{
    margin: 0 5px;
    padding: 60px 0 70px 0;
  }
  .our-value{
    .embla__slide{
      min-width: 80%;
    }
  } 
}
@media (max-width:576px){
  .about-image-section{
    border-radius: 20px;
    margin: 0 5px;
    height: 220px;
  }
  .mission{
    padding: 40px 0;
    img{
      margin-bottom: 15px;
    }
  }
  .vision_outer{
    border-radius: 0;
    margin: 0;
  }
  .our-promise {
    padding: 50px 0;
    h3{
      margin-bottom: 20px;
    }
    p{
      font-size: 20px;
    }
  }
  .our-value {
    margin: 0;
    border-radius: 0;
    padding: 40px 0 60px 0;
  }
  .value-heading{
    margin-bottom: 30px;
  }
  .our-value{
    .embla__slide {
      min-width: 90%;
      .common-heading {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 30px;
      }
    }
  } 
}