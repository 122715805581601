h3 {
  margin-top: 0px;
}

pre {
  background-color: var(--button-color);
  color: var(--button-text-color);
  padding: 10px;
  border-radius: var(--border-radius);
  word-wrap: break-word;
  white-space: pre-wrap;
}

i {
  font-size: 0.9rem;
  opacity: 0.5;
}
