.footer{
  padding: 90px 0 57px 0;
  .common-heading{
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 40px;
  }
}
.emails-footer{
  a{
    background: #F4F3FA;
    border-radius: 16px;
    display: block;
    text-align: center;
    padding: 38px 5px;
    color: #0F2052;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
  }
}
.links-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 74px 0 62px 0;
  a{
    margin: 0 15px;
    font-weight: 600;
    font-size: 16px;
    color: #51608B;
  }
}
.copyright{
  font-weight: 600;
  font-size: 12px;
  color: #9FA1C8;
  line-height: 18px;
  text-align: center;
  margin-bottom: 0;
}
@media (max-width:992px){
  .footer{
    padding: 60px 0 15px 0;
    .common-heading{
      font-size: 30px;
      margin-bottom: 15px;
      line-height: 45px;
    }
  } 
  .emails-footer{
    a{
      padding: 24px 5px;
      font-size: 17px;
    }
  } 
  .links-footer{
    margin: 30px 0;
  }
}
@media (max-width:768px){
  .emails-footer{
    a{
      padding: 18px 5px;
      font-size: 14px;
    }
  }
  .links-footer{
    a{
      margin: 0 10px;
    }
  }
}
@media (max-width:576px){
  .links-footer{
    flex-wrap: wrap;
  }
  .emails-footer{
    a{
      padding: 15px 5px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}