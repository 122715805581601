@import "~bootstrap/scss/bootstrap";
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&family=Mulish:wght@300;400;500;600;700&display=swap);

// my variable 
@import './assets/css/variable.scss';

// common css
body{
  font-family: 'Mulish', sans-serif;
  color: $first-color;
  background-color: $body-bg;
}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
  font-family: 'Montserrat', sans-serif;
}
a{
  text-decoration: none;
}
p{
  font-size: 17px;
  line-height: 25px;
  color: $second-color;
}
@media (min-width:1280px){
  .container{
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width:1279px){
  .container{
    max-width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width:992px){
  .container{    
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width:576px){
  .container{    
    padding-left: 10px;
    padding-right: 10px;
  }
}
